.part:has(.part:hover) > .partActions {
    visibility: hidden !important;
}

part {
    border: 1px solid transparent;
}

part:hover {
    border: 1px solid #9c38ff !important;
}

part:hover > .selectedPartHeader {
    background-color: #9c38ff !important;
}

part.errorPart {
    border: 1px solid #ff003d;
}

part.dragging {
    background-color: red;
}

part:hover > .selectedPartHeader,
part.multiSelectedPart > .selectedPartHeader,
part.selectedPart > .selectedPartHeader,
.show-borders part > .selectedPartHeader {
    visibility: visible;
}

.selectedPart,
.multiSelectedPart,
.show-borders part {
    border: 1px solid #9c38ff;
}

.selectedPartHeader {
    white-space: pre;
    visibility: hidden;
    position: absolute;
    top: -23px;
    background-color: #9c38ff;
    color: white;
    padding-left: 3px;
    padding-right: 5px;
    left: -1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 10px;
    height: 22px;
    font-family: Inter;
    line-height: 22px;
}

.grid.dragging > part:not(.draggingPart):not(.containerPart) > .noDropZone {
    background-color: #ff003d;
    border: 1px solid #ff003d;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
}

.draggingPart {
    background-color: #ff003d;
    border: 1px solid #ff003d;
    opacity: 0.5;
}

.draggingPart .dragging > .dragging-part {
    visibility: hidden !important;
}

.drop-placeholder {
    visibility: hidden;
}

.dragging .drop-placeholder:hover {
    background-color: #ececf5;
}

.dragging .drop-placeholder {
    visibility: visible;
    border: 1px dashed #9c38ff;
    height: 30px;
}

.drop-zone {
    height: 100%;
}

.dragging.drop-zone {
    border: 1px dashed #9c38ff;
}

codex-vertical-list-part-front > .subcontainer.dragging > .dragging-part {
    display: block;
}

.draggingInProgress codex-horizontal-list-part-front > .subcontainer > part {
    margin-left: 20px;
    margin-right: 20px;
}

codex-horizontal-list-part-front > .subcontainer.dragging > .dragging-part {
    display: inline-block;
}

codex-vertical-list-part-front .dragging-part {
    height: 0;
    width: 100%;
    display: none;
    background-size: calc(100vw / 100) calc(100vw / 100);
    background-color: #ececf5;
    border: 1px solid #9c38ff;
    border-radius: 3px;
    opacity: 65%;
    pointer-events: none;
    margin: 0;
}

codex-horizontal-list-part-front .dragging-part {
    height: 100%;
    display: none;
    background-size: calc(100vw / 100) calc(100vw / 100);
    background-color: #ececf5;
    border: 1px solid #9c38ff;
    border-radius: 3px;
    opacity: 65%;
    pointer-events: none;
    margin: 0;
}

codex-vertical-list-part-front > part > .dragHandle {
    width: 100px;
    height: 30px;
}

codex-vertical-list-part-front > part > .dragHandle > mat-icon {
    bottom: 10px;
    position: absolute;
    right: 15px;
}

@keyframes append-animate {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

// Resizing parts

.resizeHandle {
    display: none;
    visibility: hidden;
    width: 20px;
    height: 20px;

    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: center;
}

.resizeHandle:hover .resizeHandleBullet {
    background-color: #9c38ff;
}

.resizeHandleBullet {
    border: 1px solid #9c38ff;
    border-radius: 15px;
    background-color: #ececf5;
    height: 10px;
    width: 10px;
    display: block;
    margin: auto;
    margin-top: calc(50% - 5px);
}

part:hover .resizeHandle,
part.multiSelectedPart .resizeHandle part.selectedPart .resizeHandle {
    visibility: visible;
    display: block;
}

.topResizeHandle {
    position: absolute;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    cursor: n-resize;
}

.bottomResizeHandle {
    position: absolute;
    bottom: -10px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    cursor: s-resize;
}

.leftResizeHandle {
    position: absolute;
    left: -10px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    cursor: w-resize;
}

.rightResizeHandle {
    position: absolute;
    right: -10px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    cursor: e-resize;
}

.rightBottomResizeHandle {
    position: absolute;
    right: -10px;
    bottom: -10px;
    cursor: se-resize;
}

.rightTopResizeHandle {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: ne-resize;
}

.leftBottomResizeHandle {
    position: absolute;
    left: -10px;
    bottom: -10px;
    cursor: sw-resize;
}

.leftTopResizeHandle {
    position: absolute;
    left: -10px;
    top: -10px;
    cursor: nw-resize;
}

/** STYLE CONFIG **/
.style-config mat-button-toggle-group,
.style-config mat-button-toggle {
    width: 100%;
}

/** PARTS **/

/** IMAGE PART **/
codex-image-part-front {
    width: 100%;
    height: 100%;

    img {
        width: inherit;
        height: inherit;
        box-sizing: border-box;
    }
}

/** HORIZONTAL DIVIDER **/
codex-horizontal-divider-part-front {
    mat-divider {
        width: 100%;
        top: 50%;
        position: absolute;
    }
}

/** BAR CHART **/
codex-bar-chart-part-front {
    height: inherit;
    width: inherit;
    display: block;

    div.chart {
        height: inherit;
        width: inherit;
        display: block;
    }
}
