.overview-header .select-all {
    max-height: 52px;
    max-width: 42px;
}

.overview-header .search-select {
    max-height: 52px;
    padding: 0;
}

.overview-header .search-select .mat-mdc-text-field-wrapper {
    max-height: 52px;
}

.overview-header .select-all mat-card-content {
    padding: 4px;
    padding-top: 6px;
}

.searchResults mat-card-content,
.searchResults mat-card-header {
    padding: 0px !important;
}

.searchResults .search-result-select {
    margin-left: 4px;
    flex: 1 1 40px;
    box-sizing: border-box;
    max-width: 40px;
    min-width: 40px;
}

.searchResults mat-card.search-result {
    padding-left: 0px;
}

.searchResults mat-card.search-result mat-card-subtitle {
    margin-bottom: 0;
}

.searchResults mat-card.search-result .search-result-container {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
}

.searchResults mat-card.search-result mat-cart-header {
    flex: 1 1 auto;
}

.searchResults mat-card.search-result .actions {
    flex: 1 1 120px;
}
