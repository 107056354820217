.workspace-selector {
    font-size: 14px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    @apply h-full;
}

.workspace-selector-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    cursor: pointer;
}

.workspace-selector-container:hover,
.workspace-selector.showcurrentcontext .workspace-selector-container {
    transition: 0.2s;
}

.workspace-selector-container:hover .workspace-name-container,
.workspace-selector.showcurrentcontext .workspace-selector-container .workspace-name-container {
    color: white !important;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.workspace-selector-container:hover .workspace-name-container .workspace-name,
.workspace-selector.showcurrentcontext .workspace-selector-container .workspace-name-container .workspace-name {
    display: block;
    transition: 0.2s;
    font-weight: bold;
}

.workspace-selector .workspace-name-container .workspace-name {
    display: none;
    transition: 0.2s;
}

.workspace-selector .workspace-name-container {
    height: 32px;
    position: relative;
    padding-right: 25px;
    color: white;
}

.workspace-selector .workspace-name-container div {
    position: absolute;
    top: 4px;
    right: 0px;
}

.workspace-selector-last-used .mdc-list-item--with-leading-icon mat-icon.mdc-list-item__start,
.workspace-selector-all .mdc-list-item--with-leading-icon mat-icon.mdc-list-item__start {
    width: 24px;
    height: 24px;
}

.workspace-selector-last-used ngx-avatar,
.workspace-selector-last-used .mdc-list-item--with-leading-icon .mdc-list-item__start,
.workspace-selector-all ngx-avatar,
.workspace-selector-all .mdc-list-item--with-leading-icon .mdc-list-item__start {
    width: 35px;
    height: 35px;
}

.workspace-selector-last-used .mdc-list-item--with-leading-icon .mdc-list-item__start,
.workspace-selector-all .mdc-list-item--with-leading-icon .mdc-list-item__start {
    margin-right: 16px;
}

.workspace-selector-last-used .recent-workspaces-title {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.workspace-selector-last-used .see-all-workspaces {
    float: right;
    position: absolute;
    right: 15px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}

.workspace-selector-last-used mat-card-header {
    padding: 0;
}

.workspace-selector-last-used mat-list-item,
.workspace-selector-all mat-list-item {
    margin-top: 2px;
    border-radius: 0.5rem !important;
    cursor: pointer !important;
    transition: 0.2s;
}

.workspace-selector-last-used mat-list-item .mat-mdc-list-item-line,
.workspace-selector-all mat-list-item .mat-mdc-list-item-line {
    color: rgb(84, 90, 105);
    @apply flex justify-start items-center;
}

.workspace-selector-all mat-list-item .mat-mdc-list-item-line span,
.workspace-selector-last-used mat-list-item .mat-mdc-list-item-line span {
    @apply grow;
}

.workspace-selector-last-used .selected,
.workspace-selector-all .selected,
.workspace-selector-last-used mat-list-item:hover,
.workspace-selector-all mat-list-item:hover {
    @apply bg-accentlight;
}

.workspace-selector-last-used .selected .mat-mdc-list-item-line,
.workspace-selector-last-used mat-list-item:hover .mat-mdc-list-item-line,
.workspace-selector-all .selected .mat-mdc-list-item-line,
.workspace-selector-all mat-list-item:hover .mat-mdc-list-item-line {
    color: white;
}

.workspace-selector-last-used mat-list-item .mdc-list-item__content,
.workspace-selector-last-used .mat-mdc-list-item-line,
.workspace-selector-all mat-list-item .mdc-list-item__content,
.workspace-selector-all .mat-mdc-list-item-line {
    position: relative;
    overflow: visible;
}

.workspace-selector-last-used mat-card-content {
    padding: 12px !important;
}

.workspace-selector-last-used {
    min-width: 400px;
    height: auto;
    @apply bg-light-background_level_0 dark:bg-dark-background_level_0 overflow-hidden border dark:border-dark-border border-light-border rounded-xl;
    @apply p-0 #{!important};
    @apply border #{!important};
    @apply dark:border-dark-border #{!important};
    @apply border-light-border #{!important};
    @apply rounded-xl #{!important};
    --tw-bg-opacity: 0.9 !important;
    backdrop-filter: blur(4px);
}

.workspace-selector-last-used .mat-mdc-list-item {
    @apply h-10 #{!important};
}
