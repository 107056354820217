.toolpane .mat-mdc-tab-list .mdc-tab__text-label mat-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    margin-right: 3px;
}

.toolpane mat-form-field.mat-mdc-form-field,
.toolpane .mat-mdc-select-min-line {
    font-size: 10px;
}

.toolpane .mat-mdc-tab-list .mdc-tab__text-label,
.toolpane .mat-mdc-tab-list .mdc-tab__text-label-active {
    padding: 3px !important;
    margin: 0 !important;
    font-size: 10px;
}

.toolpane mat-tab-body .mat-mdc-tab-body-content {
    padding-top: 10px;
}

.toolpane mat-tab-body:nth-child(2) {
    margin: 0px;
}

.toolpane mat-hint {
    font-size: 8px;
    display: block;
}

.toolpane .mat-mdc-form-field-hint-wrapper {
    padding-left: 5px;
    padding-right: 5px;
}

.toolpane mat-expansion-panel.add-margin .mat-mdc-expansion-panel-body {
    margin: 10px;
}

.toolpane .mdc-tab {
    padding-left: 0px;
    padding-right: 0px;
}

.toolpane .picker mat-list-item {
    height: 60px !important;
    margin-bottom: 5px;
    transition: 0.2s;
}

.toolpane .picker mat-list-item:hover {
    border: 1px solid #9c38ff;
    border-radius: 5px;
    background-color: #d0d0e5;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    margin: -1px;
    margin-left: -15px;
}

.toolpane table .actions {
    width: 90px;
}

.toolpane .picker mat-list-item:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.toolpane .picker .scrollbar {
    height: 350px;
}

.toolpane .picker mat-list {
    overflow-x: visible;
    padding: 20px;
    padding-right: 2px;
}

.toolpane .picker mat-list mat-list-item mat-icon {
    margin-right: 10px;
}

.toolpane .picker .mat-mdc-list-item-title {
    font-size: 14px;
}

.toolpane .picker .mat-mdc-list-item-line {
    font-size: 12px;
}

.toolpane mat-button-toggle-group,
.toolpane mat-button-toggle {
    width: 100%;
}

.toolpane mat-button-toggle-group {
    height: 34px;
    margin-bottom: 15px;
}

.toolpane mat-button-toggle-group .mat-button-toggle-label-content {
    height: 34px;
    line-height: 34px;
}

.toolpane mat-button-toggle-group button {
    height: 34px !important;
    font-size: 11px !important;
}

.toolpane .mat-button-toggle-checked {
    background-color: #9c38ff;
    color: white;
}

.toolpane .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: white;
}

.toolpane mat-form-field.mat-mdc-form-field,
.toolpane .mat-mdc-select-min-line {
    font-size: 10px;
}

.toolpane textarea {
    margin-top: 5px;
}

.toolpaneSelect .contains-mat-select-search {
    min-height: 38px;
}

.toolpaneSelect .mat-select-search-no-entries-found {
    margin-top: 48px;
}

.toolpaneSelect .mat-select-search-no-entries-found span {
    height: 48px;
}

.toolpaneSelect .mat-mdc-option .mdc-list-item__primary-text {
    letter-spacing: normal !important;
}

ngx-mat-select-search .mat-select-search-clear {
    width: 24px !important;
    height: 24px !important;
    margin-top: 5px;
    padding: 0 !important;
}

ngx-mat-select-search .mat-select-search-clear mat-icon {
    font-size: 16px;
    margin-top: 4px;
}

ngx-mat-select-search .mat-select-search-clear span {
    width: 24px !important;
    height: 24px !important;
}
.editorMenu {
    margin-top: 4px;
}

.editorMenu a {
    justify-content: flex-start !important;
    height: 32px !important;
    min-height: 32px !important;
    gap: 4px;
}

.editorMenu a mat-icon {
    font-size: 16px !important;
    height: 16px !important;
    width: 16px !important;
    margin-right: 0px !important;
}

.editorMenu a span {
    font-size: 10px !important;
}

.toolpaneSelect mat-option {
    font-size: 10px;
    height: 32px;
    min-height: 32px;
    padding-left: 10px;
}

.toolpane .mdc-text-field--outlined {
    padding-left: 7px;
    padding-right: 7px;
}

.toolpane .mat-mdc-form-field-type-mat-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: 5px;
}

.toolpane .numberpicker .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: 5px;
}

.toolpane .numberpicker .mdc-text-field--outlined {
    padding-right: 5px;
    padding-left: 5px;
}

.toolpane .numberpicker label {
    overflow: visible;
}

.toolpane mat-form-field input {
    height: 24px !important;
}

.toolpane .numberpicker.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline .mdc-notched-outline__notch {
    max-width: initial;
}

.toolpane codex-size-picker div,
.toolpane codex-position-picker div {
    //display: flex;
    //justify-content: space-between;
}

.toolpane .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 34px !important;
}

.toolpane codex-template-properties,
.toolpane codex-template-actions,
.toolpane codex-authorization-link-picker,
.toolpane codex-template-parameters,
.toolpane codex-part-actions-wrapper,
.toolpane codex-action-edit-properties,
.toolpane .invocation-config,
.toolpane .invocation-next {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    display: block;
}

.toolpane codex-action-edit-argument-value-picker {
    padding-bottom: 10px;
    display: block;
}

.toolpane template-part-picker mat-form-field,
.toolpane codex-method-picker mat-form-field {
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    width: calc(100% - 20px);
}

.toolpane table {
    min-width: initial;
}

.toolpane codex-action-edit-invocation-argument-action-picker .buttonContainer button,
.toolpane codex-action-picker button {
    margin-top: 0px !important;
}

.toolpane codex-action-edit-conditionals button.mat-mdc-raised-button.mat-mdc-button-base {
    margin-top: 5px;
    margin-bottom: 5px;
}
