@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'workspace-selector';
@import 'overview';
@import 'edit-form';
@import 'toolpane';
@import 'parts';
@import 'tooltip';

@import 'nocode-x-theme';
@import 'nocode-x-templates-components';

$mat-codex-purple: (
    50: #ececf5,
    100: #d0d0e5,
    200: #b1b1d4,
    300: #9292c2,
    400: #7a7ab5,
    500: #8000ff,
    600: #8000ff,
    700: #515197,
    800: #47478d,
    900: #35357d,
    A100: #cacaff,
    A200: #9797ff,
    A400: #6464ff,
    A700: #4b4bff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);
$primary: mat.m2-define-palette($mat-codex-purple, 500);
$accent: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$warn: mat.m2-define-palette(mat.$m2-red-palette, 50);

$my-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $primary,
            accent: $accent,
            warn: $warn,
        ),
        typography: mat.m2-define-typography-config(
                $font-family: 'Inter',
            ),
        density: 0,
    )
);

@include mat.all-component-themes($my-theme);

.mdc-notched-outline__notch {
    border-right: none !important;
    border-left: none !important;
}

/* You can add global styles to this file, and also import other style files */
.minimum-density {
    mat-form-field input {
        height: 24px !important;
    }

    mat-form-field.mat-mdc-form-field,
    .mat-mdc-select-min-line {
        font-size: 10px;
    }

    .mat-mdc-form-field-infix {
        min-height: 36px;
    }
}

body {
    margin: 0;
    height: 100%;
    overflow: hidden;
}

.example-spacer {
    flex: 1 1 auto;
}

.clearfix {
    clear: both;
}

.buttonContainer button,
button.nocodexbutton {
    height: 45px !important;
}

mat-dialog-actions {
    margin-top: 10px;
    justify-content: end;
}

mat-dialog-actions button {
    margin-left: 5px !important;
    transition: 0.2s;
}

/** Link **/
.simple-link:hover {
    color: #9c38ff;
}

/** Snackbar **/

snack-bar-container.error {
    background: #ff003d;
}

snack-bar-container.success {
    background: #00cb14;
}

/** Background **/

mat-sidenav-container {
    background-image: url('/images/theme/background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 100%;
}

/** Icon list **/

ul.iconList li mat-icon {
    display: block;
    position: absolute;
}

ul.iconList li {
    display: inline;
}

ul.iconList [mat-button]:hover mat-icon,
ul.iconList [mat-icon-button]:hover mat-icon {
    color: #fff;
}

ul.iconList [mat-button]:hover,
ul.iconList [mat-icon-button]:hover,
ul.iconList .toggled {
    background-color: #9c38ff;
    opacity: 0.8;
    color: #fff;
}

ul.iconList button,
ul.iconList a {
    color: rgb(84, 90, 105);
    border: 1px solid rgb(84, 90, 105);
    background-color: white;
    transition: 0.2s;
}

/** Form fields **/
.mat-form-field-wrapper,
mat-form-field {
    width: 100%;
}

/** Dialog **/
.action-container {
    padding: 0;
}

.action-container mat-dialog-container,
.template-hierarchy-container mat-dialog-container {
    padding: 0;
}

.nocodex .swal2-modal button.swal2-confirm.styled:focus {
    border: none;
    outline: none;
}

.nocodex .swal2-modal button.swal2-confirm.styled {
    background-color: #9c38ff !important;
    border-left-color: #9c38ff !important;
    border-right-color: #9c38ff !important;
    color: #fff;
}

.nocodex .swal2-modal button.styled {
    background-color: #fafafa !important;
    box-shadow:
        0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    font-weight: 500;
    color: #000;
    line-height: 22px;
}

/** Autocomplete **/

.autocomplete-icon {
    float: left;
    color: #616161;
}

.autocomplete-text {
    color: #616161;
}

/** Expansion panel **/
.nocodex .mat-expansion-panel-body {
    padding: 0;
}

.nocodex .mat-expansion-panel-header {
    padding: 0 10px;
}

.nocodex .mat-expansion-panel-header .resetButton mat-icon {
    vertical-align: unset;
}

.nocodex .mat-expansion-panel-header .deleteButton,
.nocodex .mat-expansion-panel-header .resetButton {
    margin-top: -2px !important;
    width: 25px;
    height: 25px;
    float: right;
    position: absolute;
    right: 25px;
}

.nocodex .mat-expansion-panel-header .deleteButton ::ng-deep .mat-button-wrapper,
.nocodex .mat-expansion-panel-header .resetButton ::ng-deep .mat-button-wrapper {
    top: -8px;
    position: absolute;
    right: 0px;
}

/** Tabs **/

.mat-mdc-tab-body-content {
    padding: 5px;
}

//** Autocomplete **/
.toolbar-autocomplete .mat-option {
    font-size: 10px !important;
}

.alignment-picker-line h5,
.pixel-selector-line h5,
.degrees-picker-line h5,
.viewport-selector-line h5,
.input-line label,
.number-input-line h5 {
    float: left;
    line-height: 35px;
}

.alignment-picker-line mat-button-toggle-group,
.input-line mat-form-field,
.number-input-line mat-form-field,
.pixel-selector-line codex-pixel-selector,
.degrees-picker-line codex-degrees-picker,
.viewport-selector-line codex-viewport-selector {
    float: right;
}

.pixel-selector-line codex-pixel-selector,
.degrees-picker-line codex-degrees-picker,
.number-input-line mat-form-field {
    width: 70px;
}

.alignment-picker-line .mat-button-toggle-label-content {
    line-height: 30px !important;
    padding: 0 11px !important;
}

.alignment-picker-line mat-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
}

.alignment-picker-line mat-button-toggle-group {
    height: 35px !important;
}

.alignment-picker-line,
.pixel-selector-line,
.degrees-picker-line,
.viewport-selector-line,
.number-input-line {
    clear: both;
    font-size: 12px;
    margin-bottom: 10px;
    height: 35px;
    @apply flex flex-row justify-between items-center;
}

/** Style input **/
.style-title h4,
.style-title h5 {
    line-height: 40px;
}

.style-title h4,
.style-title h5,
.style-title button {
    display: inline-block;
}

.style-title button {
    margin-left: 10px;
}

/** Transformation **/
.transform-45-left {
    transform: rotate(45deg);
}

.transform-90-left {
    transform: rotate(90deg);
}

.transform-135-left {
    transform: rotate(135deg);
}

.transform-180-left {
    transform: rotate(180deg);
}

.transform-270-left {
    transform: rotate(270deg);
}

mat-divider.nocodex-divider {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

// TITLES

.title {
    font-size: 14px;
    font-weight: bold;
}

.subtitle {
    font-size: 12px;
    font-weight: bold;
}

// Image picker
.image-picker:hover span {
    display: block;
}

.image-picker span {
    display: none;
    text-align: center;
    color: white;
    font-weight: bold;
}

.image-picker:hover img {
    background-color: #d0d0e5;
    cursor: pointer;
}

app-arguments ngx-mat-select-search .mat-mdc-form-field-subscript-wrapper {
    height: 5px;
}

mat-tab-group.editor-tabs .editor-tabs-body:not(.editor-tab-template, .editor-tab-action) > .mat-mdc-tab-body-content {
    overflow: scroll;
}

.cdk-overlay-container {
    z-index: 999 !important;
}
