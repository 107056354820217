.edit-form h4 {
    margin-top: 10px;
}

.edit-form mat-card {
    padding: 15px;
}

.edit-form .icon-picker-container {
    margin: auto;
    position: absolute;
}

.edit-form mat-card .mat-form-field-prefix,
.edit-form mat-card .mat-form-field-suffix {
    color: rgb(84, 90, 105);
}

.edit-form mat-card icon-picker {
    top: 0px;
    left: 0px;
}

.edit-form .buttonContainer {
    margin-top: 10px;
    text-align: end;
}

.edit-form .buttonContainer button {
    margin-left: 5px;
}

.edit-form .general-information-container {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
}

.edit-form .name-and-description-container {
    margin-right: 10px;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
}

.edit-form .icon-picker-edit-container {
    flex: 1 1 170px;
    box-sizing: border-box;
    max-width: 170px;
    min-width: 170px;
}

.edit-form mat-divider {
    margin-top: 10px;
    margin-bottom: 10px;
}

.edit-form .tabs .mat-mdc-tab-body-wrapper {
    margin-top: 10px;
}
